import React, {useContext} from "react";
import moment from "moment";

import ProjectContext from "../../context/ProjectContext";

const SubscriptionOverview = () => {
    const { subscriptionType, subscription } = useContext(ProjectContext);
  // const [overview, setOverview] = useState({
  //   currentPeriodEnd: 1688143582,
  //   didPrice: 200,
  //   didQty: 0,
  //   userPrice: 1500,
  //   userQty: 1,
  // });

    const handleTotal = () => {
        const userTo = (subscription.userQty * subscription.userPrice) / 100;
        const numTo = (subscription.didQty * subscription.didPrice) / 100;
        return userTo + numTo;
    };
    return (
        <div className="trailEnded bg-white p-4">
            <h6 className="pb-1">Subscription Overview</h6>
            {subscription && subscriptionType ? (
                <>
                    <p>
                        Next payment on{" "}
                        {moment.unix(subscription.currentPeriodEnd).format("MMM DD, YYYY")}
                    </p>
                    <div className="subTotaldiv">
                        <div className="subTotalitem">
                            <div className="itemPrice">
                                {subscription.userQty} x ${subscription.userPrice / 100}
                                <span>user/month</span>
                            </div>
                            <div className="totalPrice">
                                ${(subscription.userQty * subscription.userPrice) / 100}
                            </div>
                        </div>
                        <div className="subTotalitem">
                            <div className="itemPrice">
                                {subscription.didQty} x ${subscription.didPrice / 100}
                                <span>number/month</span>
                            </div>
                            <div className="totalPrice">
                                ${(subscription.didQty * subscription.didPrice) / 100}
                            </div>
                        </div>
                        <div className="subTotalitem">
                            <div className="itemPrice">Total</div>
                            <div className="totalPrice">${handleTotal()}</div>
                        </div>
                    </div>
                </>
            ) : (
                <h3>No Data</h3>
            )}
        </div>
    );
};

export default SubscriptionOverview;
