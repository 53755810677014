import React, { useEffect } from "react";

const NoticeComponent = (props) => {
    const { notices, setNotices } = props;

    useEffect(() => {
        const errorTimeouts = notices.error.map((_, index) => {
            return setTimeout(() => {
                setNotices((prevNotices) => ({
                    ...prevNotices,
                    error: prevNotices.error.filter((_, i) => i !== index),
                }));
            }, 3000);
        });

        return () => {
            errorTimeouts.forEach((timeout) => clearTimeout(timeout));
        };
    }, [notices.error]);

    const handleClose = (index) => {
        setNotices((prevNotices) => ({
            ...prevNotices,
            error: prevNotices.error.filter((_, i) => i !== index),
        }));
    };
    return (
        <div className="notice-wrapper">
            <div className="container">
                {notices.error &&
                    notices.error.map((el, ind) => (
                        <div key={ind} className="alert alert-danger alert-dismissible fade show" role="alert">
                            {el}
                            <button onClick={() => handleClose(ind)} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    ))}
                {notices.info &&
                    notices.info.map((el, ind) => (
                        <div key={ind} className="alert alert-info alert-dismissible fade show" role="alert">
                            {el}
                            <button onClick={() => handleClose(ind)} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    ))}
                {notices.success &&
                    notices.success.map((el, ind) => (
                        <div key={ind} className="alert alert-success alert-dismissible fade show" role="alert">
                            {el}
                            <button onClick={() => handleClose(ind)} type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default NoticeComponent;
