import React, {useState} from "react";
import {Route, Routes} from "react-router-dom";

import Billing from "./pages/Billing";
import {ProjectContext} from "./context/ProjectContext";
import PostSubscriptionErrorModal from "./components/PostSubscriptionErrorModal/PostSubscriptionErrorModal";
import Login from "./pages/Login";
import TrialEnded from "./pages/TrialEnded";
import NoPage from "./pages/NoPage";
import Notices from "./components/notices/Notices";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

function App() {
    const [notices, setNotices] = useState({
        success: [],
        error: [],
        info: [],
        log: [],
    })

    const addError = error => {
        setNotices({...notices, error: [...notices.error, error]})
    }

    const addLog = log => {
        setNotices({...notices, log: [...notices.log, log]})
    }

    const addSuccess = success => {
        setNotices({...notices, success: [...notices.success, success]})
    }

    return (
        <ProjectContext>
                <main>
                    <Header/>
                    <Notices notices={notices} setNotices={setNotices}/>
                    <article id="qara-content">
                        <Routes>
                            <Route path={'/'} element={<Login addSuccess={addSuccess} addError={addError} addLog={addLog}/>}/>
                            <Route path={'/login-trial'} element={<Login addSuccess={addSuccess} trial={true} addError={addError} addLog={addLog}/>}/>
                            <Route path={'/billing'} element={<Billing />} />
                            <Route path={'/trialended'} element={<TrialEnded addError={addError} addLog={addLog}/>}/>
                            <Route path={'/login'} element={<Login addSuccess={addSuccess} addError={addError} addLog={addLog}/>}/>
                            <Route path="*" element={<NoPage/>}/>
                        </Routes>
                    </article>
                    <Footer/>
                    <PostSubscriptionErrorModal/>
                </main>
        </ProjectContext>);
}

export default App;
