import React, { useState, useContext } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  AddressElement,
} from "@stripe/react-stripe-js";
import { Alert, Button } from "react-bootstrap";
import ProjectContext from "../../context/ProjectContext";
import { postDefaultCard, postSubscription } from "../../services/billing";
import { LocalStorage } from "../../app/helpers";

const CheckoutForm = () => {
  const [defaultCard, setDefaultCard] = useState(true);
  const [errorTestCard, setErrorTestCard] = useState(false);
  const [stripeError, setStripeError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const { handleCardAdded, handleShowSubscriptionError } =
    useContext(ProjectContext);

  const handlePostSubscription = async () => {
    const company = LocalStorage.getCompany();
    try {
      await postSubscription(company).then((response) => {
        window.location.reload();
      });
    } catch (ex) {
      handleShowSubscriptionError(true);
    }
  };

  const handleDefaultPay = async (id) => {
    const company = LocalStorage.getCompany();
    const bodyData = {
      setDefault: 1,
    };
    try {
      await postDefaultCard(company, id, bodyData).then((response) => {
        handlePostSubscription();
      });
    } catch (ex) {}
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements || loading) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setLoading(true);

    const result = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      // clientSecret,
      redirect: "if_required",
      // confirmParams: {
      //   return_url: window.location.origin + "/success",
      // },
    });
    setLoading(false);

    if (result.error) {
      if (result.error.code === "insufficient_funds") {
        setStripeError({ message: "Your card has insufficient funds." });
      } else {
        setStripeError({ message: result.error.message });
      }
      return;
    }

    if (defaultCard) {
      handleDefaultPay(result.setupIntent.payment_method);
    } else {
      handlePostSubscription();
    }
    handleCardAdded(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement
          options={{
            fields: {
              billingDetails: {
                address: "auto",
                email: "auto",
                phone: "auto",
                name: "auto",
              },
            },
          }}
        />
        <AddressElement
          options={{
            mode: "billing",
            fields: {
              phone: "always",
            },
            validation: {
              phone: {
                required: "always",
              },
            },
            phone_number_collection: {
              enabled: true,
            },
          }}
        />
         {/* <label className="defultCardcheck mt-3 mb-2">
        <input
          type="checkbox"
          checked={defaultCard}
          onChange={(e) => setDefaultCard(e.target.checked)}
        />
        <span>Set as preferred payment method</span>
      </label> */}
        {stripeError && (
          <div className="pt-2 declinedMsg">
            <Alert variant="danger">{stripeError.message}</Alert>
          </div>
        )}
        <Button className="w-100 mt-2" type="submit" disabled={!stripe || loading}>
        {loading ? "Processing..." : "Submit"}
        </Button>
      </form>
    </>
  );
};

export default CheckoutForm;