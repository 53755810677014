import React, { useState, useEffect, useContext } from "react";
import { getTrialDaysLeft, postPayments } from "../../services/billing";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../components/loader/Loader";
import StripeElements from "./StripeElements";

import ProjectContext from "../../context/ProjectContext";
import TrialEnded from "../../pages/TrialEnded";

import AnnouncementImg from "../../assets/images/announcement.svg";
import {LocalStorage} from "../../app/helpers";

const TrialDays = () => {
  const { handleCardAdded, cardAdded, handleTrialDaysLeft, trialDaysLeft } =
    useContext(ProjectContext);
  const [data, setData] = useState();
  const [companyId, setCompanyId] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [stripeData, setStripeData] = useState();
  const [trail, setTrail] = useState(false);

  // const trialDaysLeftFunction = async (company) => {
  //   try {
  //     await getTrialDaysLeft(company).then((response) => {
  //       setData(response.data);
  //       setLoading(false);
  //       setTrail(true);
  //       // Update trialDaysLeft in the context
  //       handleTrialDaysLeft(response.data.trialDaysLeft);
  //     });
  //   } catch (ex) {
  //     setLoading(false);
  //     setError(true);
  //     setTrail(false);
  //   }
  // };

  const getStarted = async (company) => {
    setLoading(true);
    try {
      await postPayments(company).then((response) => {
        setStripeData(response.data);
        handleCardAdded(true);
        setLoading(false);
      });
    } catch (ex) {
      setLoading(false);
      setError(true);
      console.log("ex:", ex);
    }
  };

  const handleGetStarted = () => {
    const company = LocalStorage.getCompany();
    getStarted(company);
  };

  // useEffect(() => {
  //   const company = LocalStorage.getCompany();
  //   getStarted(company);
  //   setCompanyId(company);
  //   // trialDaysLeftFunction(company);
  // }, []);

  if (error) {
    return <div className="error">Error</div>;
  }

  return (
    <>
      {trialDaysLeft > 0 ? (
        <>
          <section className="accountBilling bg-lightBlue">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h2>Billing</h2>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-md-6">
                  <div className="trialDays bg-white">
                    <h5>Subscription Overview</h5>
                    <p>You are on a 30-day free trial</p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: (trialDaysLeft / 30) * 100 + "%" }}
                        aria-valuenow="100"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <p>{trialDaysLeft} days remaining</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="trialIncludes bg-white">
                    <h5>Your free trial includes</h5>
                    <ul>
                      <li>Up to 3 users</li>
                      <li>1 number</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row upgradeAccount bg-blueDark">
                <div className="col-md-7">
                  <div className="upgradeDetails">
                    <h3>Upgrade to the paid subscription </h3>
                    <p>
                      Unlock the full potential of our service. Add more users
                      and numbers by upgrading to the paid subscription.
                    </p>

                    <div className="fetureCompare">
                      <div className="d-flex twoPlan">
                        <h5>
                          $15<span>user/month</span>
                        </h5>
                        <h5>
                          $2<span>number/month</span>
                        </h5>
                      </div>
                    </div>

                    <Button onClick={handleGetStarted}>Get Started</Button>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="anouncmentImg">
                    <img
                      src={AnnouncementImg}
                      className="announcementimg"
                      alt="Qara"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Modal
            className="addNewCardModak confirmModel "
            show={cardAdded}
            onHide={() => handleCardAdded(false)}
            centered
            size="md"
          >
            <Modal.Header closeButton>
              <Modal.Title as="h4">
                Add a payment method to upgrade to the paid subscription.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="modalP cardDet">Card details</p>
              <div className="stripeForm">
                {stripeData && !loading && (
                  <StripeElements stripeData={stripeData} />
                )}
                {/* <StripeElements /> */}
              </div>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <TrialEnded />
      )}
    </>
  );
};

export default TrialDays;
