import React, { useState, useEffect } from "react";
import moment from "moment";
import { getInvoice } from "../../services/billing";
import { LocalStorage } from "../../app/helpers";

const BillingHistory = () => {
  const [moreItm, setMoreItm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState([]);
  const invoice = async (company) => {
    setLoading(true);
    try {
      await getInvoice(company).then((response) => {
        setInvoiceData(response.data);
        setLoading(false);
      });
    } catch (ex) {
      console.log(ex);
      setLoading(false);
    }
  };
  useEffect(() => {
    const company = LocalStorage.getCompany();
    invoice(company);
  }, []);
  if (loading) {
    return <div className="col-md-12">
    <div className="trailEnded bg-white p-4 mt-md-0 mt-4">
       <h6>Billing History</h6>
       <ul className="subTotal">
          <li className="">---<span>--</span></li>
          <li className="">--<span>--</span></li>
       </ul>
    </div>
 </div>;
  }
  return (
    <div className="trailEnded bg-white p-4 mt-md-0 mt-4">
      <h6>Billing History</h6>
      {invoiceData && invoiceData.length > 0 ? (
        <>
          <ul className="subTotal">
            {invoiceData.map((item, index) => (
              <li key={index} className={index > 2 && !moreItm ? "d-none" : ""}>
                {moment.unix(item.periodStart).format("MMM DD, YYYY")}
                <span>${item.total / 100}</span>
              </li>
            ))}
          </ul>
          {invoiceData.length > 2 && (
            <button onClick={() => setMoreItm(!moreItm)}>
              {moreItm ? "Les More" : "View More"}
            </button>
          )}
        </>
      ) : (
        <h3>No Data</h3>
      )}
    </div>
  );
};

export default BillingHistory;
