import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ReactCountryFlag } from "react-country-flag";
import close from "../../assets/images/login/close.svg";
import { imgPrefix } from "../../constants/global";
  const CustomModal = ({ show, onHide, onSelectCountry }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountryDetails, setSelectedCountryDetails] = useState(null);
  const [filter, setFilter] = useState("");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/public/v1/countries");
        return response;
      } catch (error) {
        console.error("Error fetching data ", error);
      }
    };
    fetchData().then(res => setCountries(res?.data));
  }, []);

  const filteredCountries = countries?.filter(
    (country) =>
      country.country.toLowerCase().includes(filter.toLowerCase()) ||
      country.destination.toLowerCase().includes(filter.toLowerCase())
  );

  const handleCountryClick = (countryId) => {
    const selectedCountry = countries.find(
      (country) => country.id === countryId
    );
    setSelectedCountries([countryId]);
    setSelectedCountryDetails(selectedCountry);

    onSelectCountry(selectedCountry);
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header className="login-modal__header">
        <Modal.Title>Select country</Modal.Title>
      </Modal.Header>
      <Modal.Body className="login-modal-body">
        <div>
          <div className="input-gr">
            <div className="input-group login-modal__search">
              <span className="input-group-text" id="basic-addon1">
                <img src={imgPrefix + '/images/login/search.svg' } alt="search" />
              </span>
              <input
                type="text"
                className="form-control login-modal-input"
                placeholder="Type name or number"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              />
              {!!filter.length && (
                <button
                  className="login-modal-input__close"
                  onClick={() => {
                    setFilter("");
                  }}
                >
                  <img src={close} alt="close" />
                </button>
              )}
            </div>
          </div>
          <div className="login-modal-select" multiple id="inputGroupSelect01">
            {filteredCountries?.map((country) => (
              <div
                key={country.id}
                value={country.id}
                onClick={() => handleCountryClick(country.id)}
                className={
                  selectedCountries.includes(country.id) ? "selected" : ""
                }
              >
                <ReactCountryFlag
                  countryCode={country.iso}
                  svg
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "16px",
                    borderRadius: "20px",
                    objectFit: "cover",
                  }}
                  className={
                    selectedCountries.includes(country.iso) ? "selected" : ""
                  }
                />
                {country.country}{" "}
                <span className="login-modal__destination">
                  ({country.destination})
                </span>
              </div>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn button-secondary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
