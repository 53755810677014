import React from 'react'

const Loader = () => {
    return (
        <div className="pageLoader">
            {/* <div className="loaderClock"></div> */}
            <div className="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div> 
    )
}

export default Loader
