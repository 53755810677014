import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { globalConstants } from "../../services/globalVariables";
const SITE_URL = globalConstants.SITE_URL;
const PhoneNotFoundModal = ({ show }) => {
  function refreshPage() {
    window.location.reload();
  }
  return (
    <Modal className="modal" show={show} backdrop="static" centered>
      <div className="modal-content px-4 py-3 phone-not-found-container">
        <h5 className="modal-title mb-2">Phone number not found</h5>
        <p className="phone-not-found-description">
          The phone number you entered does not belong to an existing user.
          Would you like to create a new account instead?
        </p>
        <div className="phone-not-found-footer">
          <Link
            to="/login"
            onClick={refreshPage}
            className="phone-not-found-footer__button"
          >
            Try another number
          </Link>

          <Link
            type="button"
            className="btn btn-primary"
            onClick={refreshPage}
            to="https://qara.app/try-qara-for-free/"
            rel="home"
            aria-current="page"
          >
            Create a new account
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default PhoneNotFoundModal;
