import React from "react";
import { Modal, Button } from "react-bootstrap";

const PostSubscriptionErrorModal = ({
  showSubscriptionError,
  handleShowSubscriptionError,
}) => {
  return (
    <Modal
      show={showSubscriptionError}
      onHide={() => handleShowSubscriptionError(false)}
      className="addNewCardModak confirmModel faildPaymentPop"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h4">Payment was declined.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modalP cardDet">
          Please choose a valid card or add a new one.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => handleShowSubscriptionError(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PostSubscriptionErrorModal;
