import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SetupForm from "./SetupForm";
import CheckoutForm from "./CheckoutForm";
const StripeElements = ({ stripeData }) => {
  let stripePromise = loadStripe(stripeData.stripePk);
  const options = {
    clientSecret: stripeData.clientSecret,
    // mode: "subscription",
    // currency: "usd",
    // amount: 15,
    // automatic_payment_methods: { enabled: true },
  };
  return (
    <div>
      <Elements stripe={stripePromise} options={options}>
        {/* <SetupForm stripeData={stripeData} /> */}
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default StripeElements;
