import React from "react";
import {globalConstants} from "../../services/globalVariables";

const SITE_URL = globalConstants.SITE_URL;

export default function MainMenu() {
    return (
        <ul className="menu-main-menu navbar-nav d-flex ms-auto main-menu-ul">
            <li className="menu-item">
                <a href={`${SITE_URL}download-app`}>Download App</a>
            </li>
            <li className="menu-item">
                <a href={`${SITE_URL}blog`}>Blog</a>
            </li>
            <li className="menu-item">
                <a href={`${SITE_URL}faq`}>FAQ</a>
            </li>
        </ul>
    )
}