import React, { useState, useEffect, useContext } from "react";

import Cards from "../components/cards/Cards";
import SubscriptionOverview from "../components/subscriptionOverview/SubscriptionOverview";
import BillingHistory from "../components/billingHistory/BillingHistory";

const BillingCards = () => {
  return (
    <>
      <section className="billingScreens bg-lightBlue">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Billing</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <SubscriptionOverview />
              <div className="mt-4">
                <Cards />
              </div>
            </div>
            <div className="col-md-6">
              <BillingHistory />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BillingCards;
