const configs = {
    local: {
        SITE_URL: "https://my.qara.app/",
        BASE_URL: "https://dev.api.cleartalks.com/api/",
        BASE_MESSAGE: "Local",
    },
    testing: {
        SITE_URL: "https://my.qara.app/",
        BASE_URL: "https://dev.api.cleartalks.com/api/",
        BASE_MESSAGE: "Testing",
    },
    staging: {
        SITE_URL: "https://my.qara.app/",
        BASE_URL: "https://dev.api.cleartalks.com/api/",
        BASE_MESSAGE: "Staging",
    },
    production: {
        SITE_URL: "https://qara.app/",
        BASE_URL: "https://api.qara.app/api/",
        BASE_MESSAGE: "Production",
    }
}

const currentEnv = process.env.REACT_APP_ENV || "testing";

export const globalConstants = {
    VERSION: "1.0.1",
    ...configs[currentEnv],
};
