import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
/* @todo */
const SharedLayout = () => {
    return (
        <>
            <Header/>
            404
            <Footer/>
        </>
    );
};
export default SharedLayout;
