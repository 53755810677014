import React from "react";
import {ReactComponent as LinkedinLogo} from "../../assets/images/linkedin.svg";
import {ReactComponent as FacebookLogo} from "../../assets/images/facebook.svg";
import {ReactComponent as InstagramLogo} from "../../assets/images/instagram.svg";
import {ReactComponent as TwitterLogo} from "../../assets/images/twitter.svg";
import {ReactComponent as YoutubeLogo} from "../../assets/images/youtube.svg";
import {ReactComponent as RedditLogo} from "../../assets/images/reddit.svg";
import {ReactComponent as PinterestLogo} from "../../assets/images/pinterest.svg";

export default function SocialLinks() {
    return (
        <ul>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://linkedin.com/">
                    linkedin
                    <span className="svg-icon"><LinkedinLogo/></span>
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://facebook.com/">
                    facebook
                    <span className="svg-icon"><FacebookLogo/></span>
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://instagram.com/">
                    instagram
                    <span className="svg-icon"><InstagramLogo/></span>
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://twitter.com/">
                    twitter
                    <span className="svg-icon"><TwitterLogo/></span>
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://youtube.com/">
                    youtube
                    <span className="svg-icon"><YoutubeLogo/></span>
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://reddit.com/">
                    reddit
                    <span className="svg-icon"><RedditLogo/></span>
                </a>
            </li>
            <li className="menu-item">
                <a target="_blank" rel="noreferrer" href="https://pinterest.com/">
                    pinterest
                    <span className="svg-icon"><PinterestLogo/></span>
                </a>
            </li>
        </ul>
    )
}