export const LocalStorage = {
    fields: {
        accessToken: "accessToken",
        refreshToken: "refreshToken",
        user: "userData",
        company: "company"
    },
    setAccessToken: function (token = null) {
        token ? window.localStorage.setItem(this.fields.accessToken, token) : window.localStorage.removeItem(this.fields.accessToken);
    },
    getAccessToken: function () {
        return window.localStorage.getItem(this.fields.accessToken)
    },
    setRefreshToken: function (token = null) {
        token ? window.localStorage.setItem(this.fields.refreshToken, token) : window.localStorage.removeItem(this.fields.refreshToken);
    },
    getRefreshToken: function () {
        return window.localStorage.getItem(this.fields.refreshToken)
    },
    setUser: function (userData = null) {
        userData ? window.localStorage.setItem(this.fields.user, userData) : window.localStorage.removeItem(this.fields.user)
    },
    getUser: function () {
        const data = window.localStorage.getItem(this.fields.user);
        return data ? JSON.parse(data) : null;
    },
    setCompany: function (companyId = null) {
        companyId ? window.localStorage.setItem(this.fields.company, companyId) : window.localStorage.removeItem(this.fields.company)
    },
    getCompany: function () {
        return window.localStorage.getItem(this.fields.company)
    },
}
