import React, {Component, useContext} from "react";

const Context = React.createContext({
    cardAdded: false,
    user: {},
    subscription: {},
    subscriptionType: false,
    subscriptionStatus: null,
    showSubscriptionError: false,
    trialDaysLeft: 0
});

export class ProjectContext extends Component {

    state = {
        cardAdded: false,
        user: {},
        subscription: {},
        subscriptionType: false,
        subscriptionStatus: null,
        showSubscriptionError: false,
        trialDaysLeft: 0
    };

    handleCardAdded = (st) => {
        this.setState({ cardAdded: st });
    };
    handleUser = (data) => {
        this.setState({ user: data });
    };
    handleSubscription = (data) => {
        this.setState({ subscription: data });
    };
    handleSubscriptionType = (data) => {
        this.setState({ subscriptionType: data });
    };
    handleSubscriptionStatus = (data) => {
        this.setState({ subscriptionStatus: data });
    };
    handleShowSubscriptionError = (data) => {
        this.setState({ showSubscriptionError: data });
    };
    handletrialDaysLeft = (data) => {
        this.setState({trialDaysLeft: data})
    }

    render() {
        return (
            <Context.Provider
                value={{
                    ...this.state,
                    handleCardAdded: this.handleCardAdded,
                    handleUser: this.handleUser,
                    handleSubscription: this.handleSubscription,
                    handleSubscriptionType: this.handleSubscriptionType,
                    handleSubscriptionStatus: this.handleSubscriptionStatus,
                    handleShowSubscriptionError: this.handleShowSubscriptionError,
                    handletrialDaysLeft: this.handletrialDaysLeft
                }}
            >
                {this.props.children}
            </Context.Provider>
        );
    }
}

export const useProjectContext = () => useContext(Context);
export default Context;

// ($env:HTTPS = "true") -and (npm start)
