import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Cards from "../components/cards/Cards";

const PaymentFailed = () => {
  const [upgrad, setUpgrad] = useState(false);
  return (
    <>
      <section className="billingScreens bg-lightBlue">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Billing</h1>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-md-12">
              <div className="trailEnded bg-white mb-5 p-5">
                <figure>
                  <svg
                    width="164"
                    height="121"
                    viewBox="0 0 164 121"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_159_11557)">
                      <path
                        opacity="0.1"
                        d="M2.40918 58.5059C4.97804 68.4338 10.4115 77.0158 18.9981 81.176C36.8485 89.8217 77.1058 85.3628 107.151 80.2443C122.693 77.5971 136.354 70.2913 146.277 60.0099L2.40918 58.5059Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M159.966 11.7725H143.481C142.698 11.7725 142.057 11.1927 142.057 10.4844C142.057 9.77595 142.698 9.19474 143.481 9.19474H146.332C145.548 9.19474 144.908 8.61501 144.908 7.90514C144.908 7.19526 145.548 6.61701 146.332 6.61701H144.297C143.513 6.61701 142.871 6.03728 142.871 5.3274C142.871 4.61753 143.513 4.03927 144.297 4.03927H152.901C150.992 3.23623 148.76 2.66537 146.189 2.36515C122.726 -0.360474 114.349 1.44231 111.394 3.29538C107.711 5.6339 103.62 7.25654 99.3353 8.07817C84.8598 10.7801 57.7884 14.0308 33.2519 7.04293C21.0568 3.56307 11.9926 9.43581 6.56055 19.2528H162.829C162.625 16.4 162.014 13.7646 160.893 11.459C160.628 11.6639 160.301 11.7743 159.966 11.7725V11.7725Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M1.012 37.7832C0.378804 43.5571 0.594048 49.3926 1.65089 55.1042L146.389 59.8944C150.373 55.7621 153.758 51.0916 156.445 46.0192L1.012 37.7832ZM58.2575 50.1395H55.4077C56.1915 50.1395 56.8334 50.7207 56.8334 51.4291C56.8334 52.1375 56.1915 52.7172 55.4077 52.7172H38.9223C38.14 52.7172 37.4981 52.1375 37.4981 51.4291C37.4981 50.7207 38.14 50.1395 38.9223 50.1395H41.7736C40.9898 50.1395 40.3495 49.5597 40.3495 48.8513C40.3495 48.143 40.9898 47.5617 41.7736 47.5617H39.7372C38.9534 47.5617 38.3115 46.982 38.3115 46.2736C38.3115 45.5652 38.9534 44.984 39.7372 44.984H56.2226C57.0064 44.984 57.6468 45.5637 57.6468 46.2736C57.6468 46.9835 57.0064 47.5617 56.2226 47.5617H58.2575C59.0414 47.5617 59.6817 48.1415 59.6817 48.8513C59.6817 49.5612 59.0414 50.1395 58.2575 50.1395Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M162.835 19.3418L4.81675 22.8172C2.89417 27.288 1.61935 32.3429 1.03223 37.5812H160.066C161.101 34.5164 161.869 31.3679 162.362 28.1709C162.836 25.0933 163.026 22.1162 162.835 19.3418ZM33.1156 30.7368H30.2658C31.0496 30.7368 31.6914 31.318 31.6914 32.0264C31.6914 32.7348 31.0496 33.316 30.2658 33.316H13.7804C12.9981 33.316 12.3562 32.7348 12.3562 32.0264C12.3562 31.318 12.9981 30.7368 13.7804 30.7368H16.6317C15.8479 30.7368 15.2075 30.157 15.2075 29.4486C15.2075 28.7402 15.8479 28.159 16.6317 28.159H14.5953C13.8115 28.159 13.1696 27.5793 13.1696 26.8709C13.1696 26.1625 13.8115 25.5813 14.5953 25.5813H31.0807C31.8645 25.5813 32.5048 26.161 32.5048 26.8709C32.5048 27.5808 31.8645 28.159 31.0807 28.159H33.1156C33.8995 28.159 34.5398 28.7402 34.5398 29.4486C34.5398 30.157 33.8995 30.7368 33.1156 30.7368Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M81.2702 116.487C117.779 116.487 147.374 113.764 147.374 110.405C147.374 107.047 117.779 104.324 81.2702 104.324C44.7619 104.324 15.166 107.047 15.166 110.405C15.166 113.764 44.7619 116.487 81.2702 116.487Z"
                        fill="#007AFF"
                      />
                      <g opacity="0.1">
                        <path
                          opacity="0.1"
                          d="M125.586 42.9979C125.616 42.94 125.643 42.8803 125.666 42.8189C125.833 42.3546 125.759 41.8443 125.76 41.3504C125.76 41.1856 125.776 41.0213 125.806 40.8594C125.67 41.1516 125.603 41.4717 125.613 41.794C125.611 42.1919 125.658 42.6089 125.586 42.9979Z"
                          fill="black"
                        />
                        <path
                          opacity="0.1"
                          d="M146.857 60.8979C146.801 61.004 146.776 61.1242 146.786 61.244C147.121 60.9142 147.875 61.1079 148.138 60.6835C148.199 60.5786 148.223 60.4561 148.206 60.3359C147.863 60.6613 147.109 60.4676 146.857 60.8979Z"
                          fill="black"
                        />
                        <path
                          opacity="0.1"
                          d="M144.684 63.428C144.656 63.5759 144.628 63.7341 144.593 63.8879C145.498 64.1704 146.543 63.4443 146.691 62.5273C146.332 63.2328 145.445 63.7046 144.684 63.428Z"
                          fill="black"
                        />
                      </g>
                      <path
                        opacity="0.1"
                        d="M127.06 113.067C126.63 113.083 124.184 113.067 124.184 113.067C124.148 113.029 124.114 112.99 124.083 112.949C124.709 112.736 126.06 112.416 127.06 113.067Z"
                        fill="black"
                      />
                      <path
                        opacity="0.1"
                        d="M134.057 113.067C133.627 113.083 131.181 113.067 131.181 113.067C131.145 113.029 131.111 112.99 131.08 112.949C131.703 112.736 133.054 112.416 134.057 113.067Z"
                        fill="black"
                      />
                      <path
                        d="M120.797 64.5426C121.532 64.5426 122.128 64.1387 122.128 63.6404C122.128 63.1422 121.532 62.7383 120.797 62.7383C120.062 62.7383 119.466 63.1422 119.466 63.6404C119.466 64.1387 120.062 64.5426 120.797 64.5426Z"
                        fill="#575A89"
                      />
                      <path
                        d="M77.4124 113.856C102.61 113.856 123.037 93.4293 123.037 68.2317C123.037 43.0341 102.61 22.6074 77.4124 22.6074C52.2148 22.6074 31.7881 43.0341 31.7881 68.2317C31.7881 93.4293 52.2148 113.856 77.4124 113.856Z"
                        fill="#99CAFF"
                      />
                      <path
                        d="M101.351 91.1591C101.351 98.3613 90.0283 91.7743 77.8894 91.7743C65.7505 91.7743 53.4727 98.0892 53.4727 90.8869C53.4727 86.78 57.3858 81.0685 62.9998 76.9202C67.2295 73.7938 72.4234 71.5547 77.641 71.5547C83.0064 71.5547 88.2625 73.9786 92.4552 77.3062C97.7467 81.5018 101.351 87.1394 101.351 91.1591Z"
                        fill="#444053"
                      />
                      <path
                        d="M92.4539 77.3042C86.0014 76.3325 74.3314 75.1479 63 76.9182C67.2297 73.7918 72.4236 71.5527 77.6412 71.5527C83.0052 71.5527 88.2612 73.9766 92.4539 77.3042Z"
                        fill="#F6F8FB"
                      />
                      <path
                        opacity="0.15"
                        d="M53.4726 36.0254C53.4726 36.0254 39.7291 44.8116 39.8415 58.555C39.9539 72.2985 53.4726 36.0254 53.4726 36.0254Z"
                        fill="white"
                      />
                      <path
                        d="M123.018 11.2114C123.018 11.2114 110.566 10.4616 112.034 19.1872C112.034 19.1872 111.739 20.7282 113.141 21.4292C113.141 21.4292 113.163 20.7829 114.417 21.0018C114.864 21.0766 115.318 21.0984 115.77 21.0669C116.381 21.0253 116.962 20.7854 117.424 20.3836V20.3836C117.424 20.3836 120.924 18.9387 122.286 13.2168C122.286 13.2168 123.293 11.9686 123.253 11.6492L121.152 12.5454C121.152 12.5454 121.869 14.0613 121.3 15.3213C121.3 15.3213 121.232 12.6016 120.828 12.6593C120.747 12.6726 119.737 13.1843 119.737 13.1843C119.737 13.1843 120.971 15.8256 120.032 17.7438C120.032 17.7438 120.386 14.4902 119.343 13.3751L117.864 14.2388C117.864 14.2388 119.308 16.9659 118.329 19.1916C118.329 19.1916 118.58 15.7783 117.554 14.4488L116.212 15.4944C116.212 15.4944 117.569 18.183 116.742 20.0287C116.742 20.0287 116.634 16.0549 115.922 15.7546C115.922 15.7546 114.75 16.7899 114.571 17.2143C114.571 17.2143 115.5 19.165 114.924 20.1943C114.924 20.1943 114.571 17.5471 114.281 17.5323C114.281 17.5323 113.113 19.2863 112.99 20.4901C112.99 20.4901 113.042 18.7006 113.997 17.3652C113.997 17.3652 112.867 17.5589 112.209 18.2924C112.209 18.2924 112.391 17.0516 114.279 16.9437C114.279 16.9437 115.245 15.6127 115.504 15.5328C115.504 15.5328 113.618 15.3746 112.475 15.8818C112.475 15.8818 113.481 14.712 115.849 15.2429L117.18 14.1633C117.18 14.1633 114.698 13.8232 113.645 14.1988C113.645 14.1988 114.856 13.1636 117.536 13.9178L118.978 13.0556C118.978 13.0556 116.862 12.6001 115.6 12.7599C115.6 12.7599 116.931 12.0426 119.399 12.8205L120.435 12.3576C120.435 12.3576 118.883 12.0529 118.429 12.0056C117.975 11.9583 117.952 11.8311 117.952 11.8311C118.937 11.6709 119.947 11.7882 120.869 12.1698C120.869 12.1698 123.055 11.349 123.018 11.2114Z"
                        fill="#007AFF"
                      />
                      <path
                        d="M110.247 7.33158C110.247 7.33158 104.627 6.99144 105.284 10.9342C105.284 10.9342 105.152 11.6308 105.784 11.9473C105.784 11.9473 105.793 11.6515 106.36 11.755C106.562 11.7882 106.767 11.7981 106.971 11.7846C107.247 11.7649 107.51 11.6564 107.72 11.4755V11.4755C107.72 11.4755 109.301 10.8218 109.916 8.23668C109.916 8.23668 110.371 7.67321 110.359 7.52828L109.41 7.9335C109.41 7.9335 109.735 8.61823 109.479 9.18761C109.479 9.18761 109.448 7.95864 109.267 7.98822C109.23 7.98822 108.773 8.22484 108.773 8.22484C108.773 8.22484 109.332 9.41832 108.91 10.285C108.91 10.285 109.07 8.81493 108.6 8.31062L107.931 8.70105C107.931 8.70105 108.583 9.93446 108.141 10.9401C108.141 10.9401 108.253 9.39762 107.791 8.79718L107.184 9.26895C107.184 9.26895 107.796 10.4831 107.424 11.3172C107.424 11.3172 107.375 9.52185 107.053 9.38579C107.053 9.38579 106.523 9.85312 106.442 10.0454C106.442 10.0454 106.862 10.9327 106.602 11.3927C106.602 11.3927 106.442 10.1962 106.306 10.1918C106.306 10.1918 105.778 10.983 105.723 11.5228C105.749 11.0205 105.905 10.5335 106.177 10.1105C105.873 10.168 105.592 10.3135 105.37 10.529C105.37 10.529 105.451 9.96848 106.307 9.91967C106.307 9.91967 106.744 9.31924 106.86 9.28226C106.86 9.28226 106.008 9.21128 105.492 9.44051C105.492 9.44051 105.946 8.91106 107.017 9.14472L107.609 8.65816C107.609 8.65816 106.488 8.51027 106.011 8.67295C106.011 8.67295 106.559 8.20562 107.77 8.54725L108.421 8.15681C108.421 8.15681 107.465 7.95125 106.894 8.02519C106.894 8.02519 107.496 7.70131 108.611 8.05329L109.077 7.84329C109.077 7.84329 108.376 7.70575 108.172 7.68504C107.968 7.66434 107.955 7.60666 107.955 7.60666C108.399 7.53281 108.854 7.58402 109.271 7.75455C109.271 7.75455 110.263 7.3937 110.247 7.33158Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M159.359 108.934C161.558 108.934 163.341 108.632 163.341 108.261C163.341 107.889 161.558 107.588 159.359 107.588C157.159 107.588 155.376 107.889 155.376 108.261C155.376 108.632 157.159 108.934 159.359 108.934Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M7.57547 120.129C9.77505 120.129 11.5582 119.828 11.5582 119.456C11.5582 119.084 9.77505 118.783 7.57547 118.783C5.37589 118.783 3.59277 119.084 3.59277 119.456C3.59277 119.828 5.37589 120.129 7.57547 120.129Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M30.6946 120.973C32.8942 120.973 34.6773 120.671 34.6773 120.3C34.6773 119.928 32.8942 119.627 30.6946 119.627C28.495 119.627 26.7119 119.928 26.7119 120.3C26.7119 120.671 28.495 120.973 30.6946 120.973Z"
                        fill="#007AFF"
                      />
                      <path
                        d="M150.642 116.478C153.926 116.478 156.589 116.028 156.589 115.472C156.589 114.917 153.926 114.467 150.642 114.467C147.358 114.467 144.695 114.917 144.695 115.472C144.695 116.028 147.358 116.478 150.642 116.478Z"
                        fill="#007AFF"
                      />
                      <path
                        d="M153.1 113.829C153.371 113.607 153.569 113.309 153.668 112.973C153.74 112.634 153.595 112.226 153.271 112.102C152.908 111.962 152.519 112.215 152.224 112.47C151.93 112.724 151.591 113.014 151.205 112.961C151.404 112.781 151.552 112.553 151.636 112.299C151.72 112.044 151.737 111.773 151.685 111.51C151.668 111.401 151.622 111.299 151.551 111.214C151.349 110.998 150.984 111.09 150.741 111.26C149.972 111.801 149.757 112.845 149.753 113.786C149.676 113.447 149.741 113.094 149.74 112.751C149.738 112.408 149.642 112.011 149.349 111.829C149.168 111.732 148.964 111.684 148.758 111.69C148.412 111.677 148.027 111.711 147.791 111.964C147.495 112.279 147.573 112.805 147.829 113.147C148.085 113.489 148.472 113.711 148.829 113.949C149.116 114.119 149.36 114.352 149.545 114.63C149.566 114.67 149.584 114.711 149.598 114.753H151.764C152.249 114.507 152.698 114.196 153.1 113.829V113.829Z"
                        fill="#007AFF"
                      />
                      <path
                        d="M9.43439 101.43C9.43439 101.43 10.2463 102.492 9.05875 104.092C7.87119 105.692 6.89215 107.05 7.28406 108.049C7.28406 108.049 9.07502 105.071 10.5377 105.028C12.0003 104.985 11.0375 103.221 9.43439 101.43Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M9.43441 101.43C9.50491 101.532 9.56067 101.644 9.60004 101.762C11.0228 103.434 11.78 104.994 10.4134 105.032C9.13862 105.069 7.60352 107.347 7.24414 107.922C7.2567 107.967 7.27151 108.011 7.28851 108.054C7.28851 108.054 9.07947 105.075 10.5421 105.032C12.0048 104.989 11.0375 103.221 9.43441 101.43Z"
                        fill="black"
                      />
                      <path
                        d="M10.9437 102.781C10.9437 103.155 10.9008 103.459 10.849 103.459C10.7973 103.459 10.7559 103.163 10.7559 102.781C10.7559 102.4 10.8076 102.583 10.8609 102.583C10.9141 102.583 10.9437 102.407 10.9437 102.781Z"
                        fill="#FFD037"
                      />
                      <path
                        d="M11.4561 103.228C11.1278 103.407 10.8423 103.515 10.8172 103.469C10.792 103.424 11.039 103.243 11.3673 103.064C11.6957 102.885 11.5655 103.015 11.5907 103.064C11.6158 103.113 11.7903 103.049 11.4561 103.228Z"
                        fill="#FFD037"
                      />
                      <path
                        d="M5.14249 101.43C5.14249 101.43 4.33057 102.492 5.51665 104.092C6.70274 105.692 7.68325 107.05 7.29134 108.049C7.29134 108.049 5.49891 105.071 4.03775 105.028C2.57658 104.985 3.53788 103.221 5.14249 101.43Z"
                        fill="#007AFF"
                      />
                      <path
                        opacity="0.1"
                        d="M5.14268 101.43C5.07152 101.532 5.01572 101.644 4.97704 101.762C3.55433 103.434 2.79565 104.994 4.16364 105.032C5.43698 105.069 6.97357 107.347 7.33146 107.922C7.3194 107.967 7.30508 108.011 7.28857 108.054C7.28857 108.054 5.49614 105.075 4.03498 105.032C2.57382 104.989 3.53807 103.221 5.14268 101.43Z"
                        fill="black"
                      />
                      <path
                        d="M3.63184 102.781C3.63184 103.155 3.67325 103.459 3.72501 103.459C3.77677 103.459 3.81818 103.163 3.81818 102.781C3.81818 102.4 3.76642 102.583 3.71466 102.583C3.66289 102.583 3.63184 102.407 3.63184 102.781Z"
                        fill="#FFD037"
                      />
                      <path
                        d="M3.11299 103.228C3.4413 103.407 3.72821 103.515 3.75335 103.469C3.7785 103.424 3.53152 103.243 3.2032 103.064C2.87488 102.885 3.00355 103.015 2.97989 103.064C2.95622 103.113 2.78467 103.049 3.11299 103.228Z"
                        fill="#FFD037"
                      />
                      <path
                        d="M3.89062 107.913C3.89062 107.913 6.16223 107.844 6.84844 107.356C7.53465 106.868 10.3431 106.287 10.5132 107.06C10.6833 107.833 13.9265 110.95 11.3621 110.97C8.79764 110.991 5.40355 110.569 4.72029 110.154C4.03704 109.738 3.89062 107.913 3.89062 107.913Z"
                        fill="#A8A8A8"
                      />
                      <path
                        opacity="0.2"
                        d="M11.405 110.705C8.84053 110.726 5.44644 110.305 4.76318 109.889C4.24261 109.572 4.03556 108.435 3.96605 107.91H3.89062C3.89062 107.91 4.03852 109.743 4.71733 110.158C5.39615 110.574 8.79468 110.995 11.3591 110.974C12.0986 110.974 12.3544 110.704 12.3411 110.315C12.2376 110.553 11.9566 110.701 11.405 110.705Z"
                        fill="black"
                      />
                      <path
                        d="M61.1913 62.2497C62.0595 62.2009 66.1457 61.8933 68.7411 60.4129C69.158 60.1676 69.6289 60.029 70.1121 60.0091C70.9373 59.9855 71.8868 60.33 71.279 62.2585C70.294 65.413 64.5751 68.9624 61.0257 68.7657C57.6124 68.5764 53.5631 67.201 50.8893 62.365C50.6331 61.8998 50.5069 61.3741 50.524 60.8432C50.555 59.8671 50.91 58.6441 52.7423 60.0076C55.218 61.8489 59.915 62.1979 60.8571 62.2497C60.9684 62.2556 61.08 62.2556 61.1913 62.2497V62.2497Z"
                        fill="#444053"
                      />
                      <path
                        d="M94.0234 62.2497C94.8915 62.2009 98.9777 61.8933 101.573 60.4129C101.99 60.1676 102.461 60.029 102.944 60.0091C103.769 59.9855 104.719 60.33 104.111 62.2585C103.126 65.413 97.4071 68.9624 93.8577 68.7657C90.4444 68.5764 86.3952 67.201 83.7213 62.365C83.4651 61.8998 83.3389 61.3741 83.356 60.8432C83.3871 59.8671 83.742 58.6441 85.5744 60.0076C88.0501 61.8489 92.7471 62.1979 93.6891 62.2497C93.8005 62.2556 93.912 62.2556 94.0234 62.2497V62.2497Z"
                        fill="#444053"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_159_11557">
                        <rect
                          width="162.683"
                          height="120"
                          fill="white"
                          transform="translate(0.658203 0.972656)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </figure>
                <h5>Your payment method failed</h5>
                <p>Please update your payment information.</p>
                <div className="upgradeBtn py-4 my-3">
                  <button
                    className="btn btn-primary"
                    onClick={() => setUpgrad(true)}
                  >
                    Update payment information
                  </button>
                </div>
                <div className="reachUs">
                  <p>If you have any questions, please reach out to </p>
                  <a href="mailto:support@qara.app">
                    support@qara.app
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal className="addNewCardModak confirmModel faildPaymentPop" show={upgrad} onHide={() => setUpgrad(false)} centered size="md">
        <Modal.Header closeButton>
        <Modal.Title as="h4">Payment methods</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p className="modalP cardDet">Please choose a valid payment method or add a new one.</p>
          {upgrad && <Cards />}</Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentFailed;
