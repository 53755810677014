import React, { useContext, useEffect, useState } from "react";
import BillingCards from "./BillingCards";
import LoginFailed from "./LoginFailed";
import Subscription from "./Subscription";
import {getSubscription, getTrialDaysLeft} from "../services/billing";
import {LocalStorage} from "../app/helpers";
import {ProjectContext, useProjectContext} from "../context/ProjectContext";

const Billing = () => {
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(false);
  const {
      user,
    handleUser,
    handleSubscriptionType,
    handleSubscription,
    handleSubscriptionStatus,
    handletrialDaysLeft
  } = useProjectContext();


  const getSubscr = async () => {
    const company = localStorage.getItem("company");
    try {
      await getSubscription(company).then((response) => {
        handleSubscription(response.data);
        handleSubscriptionType(true);
        setType(true);
        setLoading(false);
        handleSubscriptionStatus(200);
      });
    } catch (ex) {
      // console.log("ex message: -", ex.response.data);
      // console.log("message: -", ex.response.status);
      if (ex.response.status === 404) {
        try {
          const companyDetails = await getTrialDaysLeft(company)
          handletrialDaysLeft(companyDetails?.data?.trialDaysLeft || 0)
        } catch(error) {
          console.log('getTrialDaysLeft', error)
        }
        handleSubscriptionStatus(ex.response.status);
      } else {
        handleSubscriptionStatus(ex.response.data);
      }
      setType(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const getUserData = localStorage.getItem("userData");
    handleUser(JSON.parse(getUserData));
    getSubscr();
  }, []);
  if (loading) {
    return ;
    // return <Loader />;
    // return (
    //   <section className="billingScreens bg-lightBlue">
    //     <div className="container">
    //       <div className="row">
    //         <div className="col-md-12">
    //           <h1>Billing</h1>
    //         </div>
    //       </div>
    //       <div className="row">
    //         <div className="col-md-6">
    //           <div className="trailEnded bg-white p-4">
    //             <h6 className="pb-1">Subscription Overview</h6>
    //             <p>---</p>
    //             <div className="subTotaldiv">
    //               <div className="subTotalitem">
    //                 <div className="itemPrice">---</div>
    //                 <div className="totalPrice">--</div></div>
    //                 <div className="subTotalitem">
    //                   <div className="itemPrice">--</div>
    //                   <div className="totalPrice">--</div></div>
    //                   <div className="subTotalitem">
    //                     <div className="itemPrice">--</div><div className="totalPrice">--</div>
    //                     </div></div></div>
    //         </div>
    //         </div>
    //     </div>
    //   </section>
    // );
  }
  // if (user.status === 0 && user.user.role === "user") {
  if (user.role === "user") {
    return <LoginFailed />;
  }
  // if (
  //   (user.status === 0 && user.user.role === "admin") ||
  //   user.user.role === "superadmin"
  // ) {
  //   return <Subscription />;
  // }
  // return <>{subscriptionType ? <BillingCards /> : <TrialDays />}</>;
  return <Subscription />;
};

export default Billing;
